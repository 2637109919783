<template>
  <div class="widgetContainer bg-white widgetContainer--scrollable pay">
    <div class="widgetContainer__header-fixed"
         v-if="paymentStatus == ''">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span class="icon-Arrow-big-left cancel"
                @click="cancel" />
          <p class="title">
            {{ $t('send_a_visa_vard') }}
          </p>
          <span class="el-icon-close"
                @click="drawerClose" />
        </div>
      </div>
    </div>
    <div class="widgetContainer__body pay-card__body">
      <div class="pay__details">
        <el-form class="wise-form"
                 :model="formData"
                 :rules="formRules">
          <el-form-item class="is-no-asterisk"
                        prop="name"
                        :label="$t('Name')">
            <el-input :value="getSelectedContactData.name" />
          </el-form-item>
          <el-form-item
            class="is-no-asterisk"
            prop="email"
            :label="$t('contact_Details_Email')">
            <el-input v-model="formData.email" />
          </el-form-item>
          <el-form-item class="is-no-asterisk"
                        prop="phone"
                        :label="$t('contact_Details_Phone')">
            <MobileInput :value="formData.phone" @input="mobileNumberUpdate" :show-country-code-selector="true" />
          </el-form-item>
          <el-form-item class="is-no-asterisk"
                        prop="amount"
                        :label="$t('pay_section_amount')">
            <Amount :precision="2"
                    v-model="formData.amount"
                    :masked="true" />
          </el-form-item>
          <el-form-item class="is-no-asterisk"
                        prop="description"
                        :label="$t('pay_row_purpose')">
            <el-input placeholder="Purpose of the payment"
                      v-model="formData.description" />
          </el-form-item>

          <el-form-item
            prop="address.line1"
            :rules="formRules.line1"
            class="is-no-asterisk"
            :label="$t('billing_address_1')">
            <el-input style="display: none" />
            <el-input v-model="formData.address.line1" style="display: none" />
            <div class="el-input">
              <gmap-autocomplete
                :placeholder="$t('billing_address_1')"
                autocomplete="disabled"
                :value="formData.address.line1"
                :options="{
                  componentRestrictions: { country: 'us' }
                }"
                @change.native="formData.address.line1 = $event.target.value"
                @place_changed="updateAddress($event)"
                :select-first-on-enter="true"
                class="el-input__inner" />
            </div>
          </el-form-item>
          
          <el-form-item prop="address.line2" :label="$t('address_2')">
            <el-input
              :placeholder="$t('address_2')"
              v-model="formData.address.addressLine2" />
          </el-form-item>
          <div class="wise-form__inline">
            <el-form-item
              prop="address.city"
              class="is-no-asterisk"
              :rules="formRules.city"
              :label="$t('address_City')">
              <el-input
                style="flex: 2"
                :placeholder="$t('address_City')"
                v-model="formData.address.city" />
            </el-form-item>
            <el-form-item
              prop="address.country"
              class="is-no-asterisk"
              :rules="formRules.state"
              :label="$t('address_State')">
              <el-input
                style="flex: 1"
                :placeholder="$t('address_State')"
                v-model="formData.address.state" />
            </el-form-item>
            <el-form-item
              prop="address.postalCode"
              class="is-no-asterisk"
              :rules="formRules.postalCode"
              :label="$t('address_Zipcode')">
              <el-input
                style="flex: 1"
                :placeholder="$t('address_Zipcode')"
                v-model="formData.address.postalCode" />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary"
                 :disabled="!isFormValid"
                 @click="next()"
                 class="width-100 el-button__brand brand">
        {{ $t('send') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex';
import Amount from '@/components/Amount';
import MobileInput from '@/components/MobileInput.vue';
import { accountType } from '@m/contact/enums/index';
import { formatGoogleMapData } from '@/utils/gmap';
import { payToContact } from '../utils/pay';

export default {
  components: {
    Amount,
    MobileInput
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    isFormValid() {
      if (
        this.isPhoneNumberValid &&
        this.formData.amount !== '' &&
          this.formData.description !== ''
          && this.formData.address.line1 !== ''
          && this.formData.address.city !== ''
          && this.formData.address.postalCode !== ''
          && this.formData.address.state !== ''

      ) {
        const amount = parseFloat('' + this.formData.amount);
        return amount >= 0.05;
      }
      return false;
    },
    accountTypes() {
      return accountType;
    }
  },
  data() {
    return {
      formData: {
        amount: '0',
        description: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          postalCode: '',
          country: 'US',
          state: ''
        }
      },
      isPhoneNumberValid: false,
      contactChange: false,
      formRules: {
        email: [{
          type: 'email',
          message: this.$t('emailInvalid'),
          trigger: 'blur'
        }],

        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]

      },
      paymentStatus: '',
      paymentInfo: ''
    };
  },
  methods: {
    ...mapActions('account', ['getAccount']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    cancel() {
      this.drawerBack();
    },
    updateAddress(e) {
      const {line1, line2, city, state, country, postalCode} = formatGoogleMapData(e);
      this.formData.address = {line1, line2, city, state, country, postalCode};
    },
    mobileNumberUpdate(v) {
      if(v) {
        const {value, isValid} = v;
        this.formData.phone = value;
        this.isPhoneNumberValid = isValid;
      }
    },
    next() {
      if(!this.isFormValid) {
        return
      }
      const loader = this.showLoader();
      payToContact('card',  {
        accountId: this.getSelectedAccount.id,
        contactId: this.getSelectedContactData.id,
        amount: '' + this.formData.amount,
        description: this.formData.description,
        type : 'virtual',
      },{
        id: this.getSelectedContactData.id,
        email: this.formData.email,
        phone: this.formData.phone,
        card: {
          address: this.formData.address
        }
      }).then(() => {
        this.setRecentTransactions([]);

        this.drawerPush('pay-success');
      }).finally(() => {
        loader.close()
      })
    }
  },
  created() {
    console.log(this.getSelectedContactData);
    const contact = this.getSelectedContactData;
    if(contact) {
      this.formData.phone = contact.phone;
      this.formData.email = contact.email;

      if(contact.phone) {
        this.isPhoneNumberValid = true;
      }

      if(contact.card && contact.card.address) {
        this.formData.address = contact.card.address;
      }

      console.log('formData',this.formData)
    }
  }
};
</script>
<style lang="scss">
  .pay {

    //position: relative;
    .widgetContainer__header-fixed {
      padding: 0;
    }

    .welcome__illustration {
      margin: 10% auto;
    }

    .header-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 41px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }

    .widgetContainer__body.pay-card__body {
      position: relative;
      padding-bottom: 70px;
    }

    &__header {
      font-size: 20px;
      font-weight: 600;
      line-height: 41px;
      padding: 6px 0;
    }

    &__details {
      .header-1 {
        text-transform: capitalize;
      }

      .sub-header {
        color: rgba(#3c3c43, 0.6);
        font-size: 13px;
        padding-bottom: 7px;
        text-transform: uppercase;
      }
    }

    .el-select:not(.el-select--mini) {
      width: 100%;
    }

    .success-desc {
      font-size: 17px;
      color: #3c3c43;
      margin-bottom: 25px;
    }

    &__done-btn {
      width: 100%;

      &.is-disabled {
        opacity: 0.5;
      }
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--branding-sidebar);
      padding: 16px;
      border-top: 1px solid var(--branding-sidebar-border);;

      button {
        width: 100%;
      }
    }
  }
</style>
